<template>
  <div style="overflow-x: hidden;background-color: white;position: absolute;left: 0;right: 0;top: 0" class="bg">
    <Navigator/>
    <SliderBanner :slider="slider"/>
    <FuncShow :funInfo="funInfo" style="background: #F4F7FF; ">
    </FuncShow>
    <Footer/>
    <ContactFloat/>
  </div>
</template>

<script>
import Navigator from '../widgets/navigator'
import Footer from '../widgets/footer'
import SliderBanner from '../widgets/SliderBanner'
import FuncShow from '../widgets/func-show'
import ContactFloat from '../widgets/contact-float.vue'

export default {
  components: {
    Navigator,
    FuncShow,
    ContactFloat,
    SliderBanner,
    Footer
  },
  data() {
    return {
      slider: {
        image: require('@/assets/images/index/short-play/phone.png'),
        title: '鐭墽鏉垮潡',
        desc: "鐭墽CPS娴烽噺浼樿川鐭墽璧勬簮 鑷甫娴侀噺 鏀寔涓夊ぇ骞冲彴鎺ㄦ祦(鎶栭煶,蹇墜,瑙嗛鍙? 浣ｉ噾楂樿揪90%"
      },
      funInfo: {
        title: '鐭墽鏉垮潡',
        list: [
          {
            title: '鐭墽鎺ㄥ箍',
            img: require('@/assets/images/index/short-play/img2.png'),
            text: '杩欓噷鏈夋姈闊?蹇墜鐭墽锛屽彧闇€瑕佸鍒舵帹骞块摼鎺ワ紝鍒涘缓鎺ㄥ箍\n' +
                '鍗冲彲瀹屾垚鍒嗕韩锛岃禋鍙栦剑閲?
          },
          {
            title: '浣ｉ噾姒滃崟鎺掑悕',
            img: require('@/assets/images/index/short-play/img3.png'),
            text: '鐢ㄦ埛鍙互鐪嬪埌褰撴棩銆佹槰鏃ャ€?澶┿€?2澶╁唴鐨勭煭鍓ф帹骞胯禋鍙栫殑浣ｉ噾鎺掕鎯呭喌'
          },
          {
            title: '浠诲姟鎺ㄥ箍璁″垝',
            img: require('@/assets/images/index/short-play/img4.png'),
            text: '涓庢姈闊炽€佸揩鎵嬪钩鍙板悎浣滃崈鍒嗕换鍔★紝涓€閿禋鍙栧弻骞冲彴鏀剁泭'
          }
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
